<template>
  <cds-cards>
    <a-form
        ref="formModal"
        :model="formState"
        :label-col="{ span: 24 }"
        label-align="top"
        :validate-trigger="['change', 'blur']"
        :rules="rules"
        @finish="onFinish"
    >
      <a-form-item :label="$t('user_group.name')" name="name">
        <cds-input
            class="w-100 ant-input-sm" v-model:value="formState.name" :maxlength="50" show-count
            size="small"
            :placeholder="$t('user_group.name')"/>
      </a-form-item>
      <a-form-item :label="$t('user_group.parentGroup')" name="parentId">
        <cds-tree-select
            :tree-data="tree"
            :field-names="fieldNames"
            v-model:value="formState.parentId"
        />
      </a-form-item>
      <a-form-item :label="$t('user_group.subGroup')" name="children">
        <cds-select
            ref="province"
            :options="groupAlone"
            :label-prop="'name'"
            :value-prop="'id'"
            :allow-clear="true"
            multiple
            v-model:value="formState.children"
        >
        </cds-select>
      </a-form-item>
      <a-form-item :label="$t('group.identity_object')" name="ioGroup">
        <cds-select
            ref="province"
            :options="listIO"
            :label-prop="'referenceName'"
            :value-prop="'referenceId'"
            :allow-clear="true"
            multiple
            v-model:value="formState.ioGroup"
        ></cds-select>
      </a-form-item>
      <a-form-item :label="$t('user_group.description')" name="description">
        <cds-input
            class="w-100 ant-input-sm" v-model:value="formState.description" :maxlength="50" show-count
            size="small"
            :placeholder="$t('user_group.description')"/>
      </a-form-item>

      <hr class="footer">

      <div class="w-100 text-right">
        <cds-button
            key="submit"
            type="primary"
            size="small"
            type-html="submit"
            class="mr-20"
        >
          <cds-feather-icons class="mr-10" type="save"/>
          {{ $t('common.update') }}
        </cds-button>
        <cds-button key="back" type="light" size="small" @click="onCancel">
          <cds-feather-icons class="mr-10" type="x"/>
          {{ $t('common.cancel') }}
        </cds-button>
      </div>
    </a-form>
  </cds-cards>
</template>

<script>
import {computed, defineComponent, reactive, watch, toRaw} from "vue";
import {maxlength, required} from "@/util/validation";
import {useStore} from "vuex";
import _ from 'lodash';

export default defineComponent({
  emits: ['cancel'],
  setup(props, {emit}) {
    const rules = {
      name: [required('Name'), maxlength(50, 'Name')],
      description: [maxlength(100, 'Description')],
    }
    const {state, dispatch} = useStore();
    const listIO = computed(() => state.ioGroup.listIO || []);
    const formState = reactive(state.ioGroup.group);
    watch(() => state.ioGroup.group, (g) => Object.assign(formState, g))
    const tree = computed(() => (state.ioGroup.treeGroup || []).filter(e => !(formState.children || []) || e.id !== formState.id));
    const groupAlone = computed(() => [...(state.ioGroup.groups || [])
        .filter(e => ((!e.parentId && formState.parentId !== e.id) || e.parentId === formState.id) && e.id !== formState.id)])
    const onCancel = () => emit('cancel')
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState))
        if (await dispatch('ioGroup/update', data)) {
          dispatch('ioGroup/getTreeGroup').then(onCancel);
        }
        // eslint-disable-next-line no-empty
      } catch {

      }
    }

    return {
      rules,
      listIO,
      formState,
      groupAlone,
      onCancel,
      tree,
      fieldNames: {label: 'name', value: 'id'},
      onFinish
    }
  }
})
</script>
