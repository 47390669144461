import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { primaryColor } from '@/config/theme/themeVariables';
import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons-vue';
import { FORM_MODE } from '@/util/common-constant';
import CreateIoGroup from '@/view/identity-management/io-group-management/create-io-group.vue';
import ViewIoGroup from '@/view/identity-management/io-group-management/view-io-group.vue';
import List from '@/view/manager/user-group/List.vue';
import Tabs from '@/components/tabs/Tabs.vue';
import ListIoGroup from '@/view/identity-management/io-group-management/list-io-group.vue';

export default {
  components: {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    ListIoGroup,
    FileSearchOutlined,
    List,
    'cds-tabs': Tabs,
    CreateIoGroup,
    PlusCircleFilled,
    CloseCircleFilled,
    ViewIoGroup,
  },
  setup() {
    const { state, dispatch } = useStore();
    const treeGroup = computed(() => state.ioGroup.treeGroup);
    const treeValue = ref(null);
    const formMode = ref(null);
    const group = computed(() => state.ioGroup.group);
    const groups = computed(() => state.ioGroup.groups);
    const activeTab = ref('1');

    const preCreate = () => {
      dispatch('ioGroup/preCreate', group).then();
      formMode.value = FORM_MODE.CREATE;
    };
    const resetForm = () => {
      dispatch('ioGroup/resetForm', group).then();
      treeValue.value = null;
    };
    const selectGroup = (id) => {
      activeTab.value = '1';
      const group = groups.value.find((e) => e.id === id);
      if (group) dispatch('ioGroup/preView', group).then();
      formMode.value = FORM_MODE.UPDATE;
    };
    const contextMenuClick = async (menu, id) => {
      switch (menu) {
        case '1':
          selectGroup(id);
          break;
        case '2':
          if (await dispatch('ioGroup/delete', { id })) {
            resetForm();
            dispatch('ioGroup/getTreeGroup').then();
          }
          break;
        case '3':
          selectGroup(id);
          break;
        case '4':
          selectGroup(id);
          break;
      }
    };

    onMounted(() => {
      dispatch('setTitleHeader', 'IO Group');
      dispatch('ioGroup/getListIO').then();
      dispatch('ioGroup/getTreeGroup').then();
    });

    return {
      treeGroup,
      treeValue,
      fieldNames: { children: 'children', title: 'name', key: 'id' },
      primaryColor: primaryColor,
      formMode,
      preCreate,
      group,
      FORM_MODE: FORM_MODE,
      resetForm,
      activeTab,
      selectGroup,
      groups,
      contextMenuClick,
    };
  },
  methods: {},
};
