<template>
  <cds-main class="">
    <div class="flex items-center justify-center mt-20">
      <a-radio-group
        v-model:value="tab"
        button-style="solid"
        :style="{ marginBottom: '8px' }"
        @change="handleChange"
      >
        <a-radio-button :value="TAB_IDENTITY.OBJECT">{{ $t('identity_object.form.Person Profile') }}</a-radio-button>
        <a-radio-button :value="TAB_IDENTITY.VEHICLE">{{ $t('identity_object.form.Vehicle Profile') }}</a-radio-button>
      </a-radio-group>
    </div>
    <Suspense>
      <template #default>
        <component :form-mode="FORM_MODE.UPDATE" :is="component" />
      </template>
      <template #fallback>
        <cds-loading />
      </template>
    </Suspense>
  </cds-main>
</template>
<script>
import { ref, defineAsyncComponent, watch } from 'vue';
import { FORM_MODE } from '@/config/Constant.js';
import { useRoute, useRouter } from 'vue-router';
import { TAB_IDENTITY } from '@/config/Constant.js';

const DetailObject = defineAsyncComponent(() =>
  import('./components/detail/Object.vue')
);
const DetailVehicle = defineAsyncComponent(() =>
  import('./components/detail/Vehicle.vue')
);
export default {
  setup() {
    const component = ref(DetailObject);
    const route = useRoute();
    const router = useRouter();
    const tab = ref(route.query.tab ? route.query.tab : TAB_IDENTITY.OBJECT);

    const handleChange = (e) => {
      tab.value = e.target.value;
      router.push({ path: '/identity/object/edit', query: { ...route.query, tab: e.target.value } })
    };

    watch(
      () => route.query,
      () => {
        if (tab.value === TAB_IDENTITY.VEHICLE) {
          component.value = DetailVehicle;
        } else {
          component.value = DetailObject;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      handleChange,
      tab,
      component,
      FORM_MODE,
      TAB_IDENTITY
    };
  },
};
</script>
