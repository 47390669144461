<template>
  <cds-main class="!pb-5 group-management">
    <div
      class="pt-4 px-[18px] bg-ems-gray900 h-[76px] border-b-1 border-solid border-ems-tag1 flex items-center gap-[8px]"
    >
      <div class="hover:bg-ems-gray700 p-2 cursor-pointer" @click="onClose">
        <img
          src="/assets/ems/left.png"
          alt="left"
          class="block mx-auto"
          width="24"
          height="24"
        />
      </div>
      <a-tabs v-model:activeKey="activeTab">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="w-full component-custom">
      <Suspense>
        <template #default>
          <component :is="component" />
        </template>
        <template #fallback>
          <cds-loading />
        </template>
      </Suspense>
    </div>
  </cds-main>
</template>
<script setup>
import { useStore } from 'vuex';
import { onMounted, defineAsyncComponent, ref } from 'vue';
import { i18n } from '@/main';
import { useRouter } from 'vue-router';

const { t } = i18n.global;
const { dispatch } = useStore();
const ListGroups = defineAsyncComponent(() =>
  import('./components/ListGroups.vue')
);
onMounted(() => {
  dispatch('setTitleHeader', t('group-management.title-header'));
  dispatch('ioGroup/getWarningLevel');
});

const component = ref(ListGroups);
const tabs = [
  { key: 'list', tabName: t('group-management.tab-1'), component: ListGroups },
];
const activeTab = ref('list');
const router = useRouter();
const onClose = () => {
  if (window.history.state.back) {
    history.back();
  } else {
    router.push({
      path: '/io-management/group-management',
      query: {},
    });
  }
};
</script>
<style lang="scss">
.group-management .component-custom {
  height: calc(100% - 76px);
}
.group-management .ant-tabs .ant-tabs-ink-bar {
  display: none;
}
.group-management .ant-tabs .ant-tabs-tab {
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-management .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
</style>
