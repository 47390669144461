<template>
  <cds-cards>
    <a-row>
      <a-col class="text-center" v-for="io in listIO" :key="io.referenceId" :xxl="6" :xl="6" :lg="6" :md="8" :xs="24">
        <a-avatar :size="200"
                  :src="io.avatar">
        </a-avatar>
        <p class="mt-2" style="font-weight: bold">{{ io.referenceName }}</p>
      </a-col>
    </a-row>
  </cds-cards>
</template>

<script>
import {computed, defineComponent, reactive} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  setup() {
    const {state} = useStore();
    const formState = reactive(state.ioGroup.group)
    const listIO = computed(() => (formState.ioGroup || []).map(e =>
        state.ioGroup.listIO.find(ele =>e === ele.referenceId) || false).filter(e => e));
    return {
      listIO,
      formState
    }
  }
});
</script>